@import '@lumapps/lumx/scss/variables.scss';

.page-card {
  background-color: lumx-color-variant('light', 'N');
  padding: $lumx-spacing-unit-huge;
  overflow: auto;
  border-radius: $lumx-border-radius;
}

@each $depth, $shadow in $lumx-elevation-shadows {
  .page-card--elevation-#{$depth} {
    @include lumx-elevation($depth);
  }
}
