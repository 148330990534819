@use 'sass:map';
@import '@lumapps/lumx/scss/variables';

.contextual-actions {
    $self: &;

    @include lumx-elevation(2);
    position: fixed;
    // Above the content but below the dialog overlays
    z-index: map.get($lumx-base-layers, "high");
    right: 0;
    margin-top: $lumx-spacing-unit;
    padding: 6px !important;
    background-color: lumx-color-variant('light', 'N');
    border-radius: $lumx-border-radius 0 0 $lumx-border-radius;

    /*
        In order to show the fixed header nav, sometimes we add a padding top to the body,
        leaving some space for the fixed elements.
        In that case, we want to add this padding to the top position, making the element display correctly right after the nav
    */
    body:not(.headless):not(.navigation-inheritance):not(.not-sticky) & {
        top: $header-height;
    }

    &__divider {
        margin: $lumx-spacing-unit 0;
    }

    &__action,
    &__skeleton {
        padding: 0 $lumx-spacing-unit-tiny !important;
        width: 100%;
        justify-content: flex-start;
    }

    &__action > i {
        margin: 0 !important;
    }

    &:not(&--is-collapsed) &__action > i {
        margin: 0 $lumx-spacing-unit-tiny !important;
    }

    &__action-label,
    &__skeleton-label {
        display: none;
        font-size: small !important;
    }

    &:focus-within &__action-label {
        display: block;
    }

    &:hover &__action-label {
        display: block;
    }

    &__skeleton {
        height: $lumx-spacing-unit-huge;
    }

    &:hover &__skeleton-icon {
        margin-left: $lumx-spacing-unit-tiny;
    }

    &:hover &__skeleton-label,
    &:focus-within &__skeleton-label {
        display: flex;
    }

    .no-background-color {
        background-color: unset;
        span {
            color: var(--lumx-button-emphasis-low-state-hover-theme-light-color);
        }
    }
}
