@import '@lumapps/lumx/scss/variables.scss';

.reset-password-form {
  width: $lumx-size-xxl;
  display: inline-block;
  &__input {
    text-align: initial;
  }
  &__password_requirement {
    text-align: initial;
    font-size: $lumx-size-xxs;
  }
  &__eye-password-icon {
    margin-top: 40%;
  }
  &__button {
    margin-top: $lumx-spacing-unit-regular;
  }
  &__error-reset {
    margin-top: $lumx-spacing-unit-big;
  }
}
