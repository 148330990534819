@import '@lumapps/lumx/scss/variables.scss';

.navigation {
  padding: 0;
  margin: 0;
  list-style: none;
  outline: none;

  &--horizontal {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    gap: $lumx-spacing-unit;
  }

  &--popover {
    min-width: 210px;
    padding: $lumx-spacing-unit;
  }

  &--horizontal,
  &--popover {
    .navigation-item {
      max-width: 216px;
    }
  }
}
