@import '@lumapps/lumx/scss/variables.scss';

@mixin link-borders($emphasis, $state) {
    border-top-width: var(--lumx-navigation-item-emphasis-#{$emphasis}-state-#{$state}-border-top-width);
    border-right-width: var(--lumx-navigation-item-emphasis-#{$emphasis}-state-#{$state}-border-right-width);
    border-bottom-width: var(--lumx-navigation-item-emphasis-#{$emphasis}-state-#{$state}-border-bottom-width);
    border-left-width: var(--lumx-navigation-item-emphasis-#{$emphasis}-state-#{$state}-border-left-width);
    border-style: solid;
}

@mixin link-colors($emphasis, $state, $theme) {
    background-color: var(
        --lumx-navigation-item-emphasis-#{$emphasis}-state-#{$state}-theme-#{$theme}-background-color
    );
    border-color: var(--lumx-navigation-item-emphasis-#{$emphasis}-state-#{$state}-theme-#{$theme}-border-color);

    .navigation-item__label {
        color: var(--lumx-navigation-item-emphasis-#{$emphasis}-state-#{$state}-theme-#{$theme}-label-color);
    }

    .navigation-item__icon {
        color: var(--lumx-navigation-item-emphasis-#{$emphasis}-state-#{$state}-theme-#{$theme}-icon-color);
    }
}

.navigation-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    order: 0;

    &__chevron {
        margin-left: auto;
        margin-right: 0;
        padding-left: $lumx-spacing-unit;
    }

    &__more {
        order: 1;
        &--hidden {
            visibility: hidden;
        }
    }

    &--hidden {
        visibility: hidden;
        order: 2;
    }

    &--horizontal {
        max-width: 216px;
    }

    &__link,
    &__section-link {
        text-decoration: none;
        overflow: hidden;
        text-overflow: ellipsis;

        @include lumx-typography('subtitle1');
    }

    &__link {
        cursor: pointer;
        display: flex;
        min-height: var(--lumx-navigation-item-min-height);
        flex: 1 1 auto;
        flex-direction: row;
        align-items: center;
        gap: $lumx-spacing-unit;
        padding: 0 var(--lumx-navigation-item-padding-horizontal);
        border-radius: var(--lumx-navigation-item-border-radius);
        outline: none;
        max-width: 100%;

        @include link-borders(low, default);
        @include link-colors(low, default, light);

        .navigation-item__label {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        &:hover,
        &.focus-visible,
        &:has(.focus-visible) {
            @include link-borders(low, hover);
            @include link-colors(low, hover, light);
        }

        &.focus-visible {
            @include lumx-state-as-selected(lumx-base-const("state", "FOCUS"), lumx-base-const("theme", "LIGHT"));
        }

        &:active {
            @include link-borders(low, active);
            @include link-colors(low, active, light);
        }

        &--is-selected {
            @include link-borders(selected, default);
            @include link-colors(selected, default, light);

            &:hover,
            &:focus,
            &:has(.focus-visible) {
                @include link-borders(selected, hover);
                @include link-colors(selected, hover, light);
            }

            &:active {
                @include link-borders(selected, active);
                @include link-colors(selected, active, light);
            }
        }

        $className: &;

        &--dark {
            @include link-colors(low, default, dark);

            &:hover,
            &:focus,
            &:has(.focus-visible) {
                @include link-colors(low, hover, dark);
            }

            &.focus-visible {
                @include lumx-state-as-selected(lumx-base-const("state", "FOCUS"), lumx-base-const("theme", "DARK"));
            }

            &:active {
                @include link-colors(low, active, dark);
            }

            &#{$className}--is-selected {
                @include link-colors(selected, default, dark);

                &:hover,
                &:focus,
                &:has(.focus-visible) {
                    @include link-colors(selected, hover, dark);
                }

                &:active {
                    @include link-colors(selected, active, dark);
                }
            }
        }
    }
}
