@import '@lumapps/lumx/scss/variables.scss';

.translations-dropdown {
    &__accept {
        float: right;
    }

    .lumx-dropdown__menu {
        padding: $lumx-spacing-unit-big;
    }

    &__lang-text-field {
        margin-bottom: $lumx-spacing-unit-big;
    }
}
