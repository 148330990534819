@import '@lumapps/lumx/scss/variables.scss';

.thumbnail-with-icon {
    &--img-rounded {
        border-radius: 50% !important;

        .lumx-thumbnail__image,
        .lumx-thumbnail__background {
            border-radius: 50% !important;
        }
    }

    &--icon {
        color: lumx-color-variant('dark', 'L2');
    }
}

@each $key, $size in $lumx-sizes {
    .thumbnail-with-icon--#{$key} {
        min-width: $size;
    }
}
