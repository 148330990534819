@import '@lumapps/lumx/scss/variables.scss';

.powered-by {
  align-items: center;
  margin-top: $lumx-spacing-unit-huge;

  &__text-powered-by {
    color: lumx-color-variant('dark', 'L2');
  }

  &__logo-powered-by {
    position: static;
    height: $lumx-spacing-unit-big;
    width: $lumx-spacing-unit-huge * 3;
    margin-left: $lumx-spacing-unit-tiny;
    margin-bottom: -$lumx-spacing-unit-tiny;
  }
}