@import '@lumapps/lumx/scss/variables.scss';

.color-bubble-letter {
    flex-shrink: 0;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;

    @each $key, $size in $lumx-sizes {
        &-#{$key} {
            width: $size;
            height: $size;
        }
    }

    &__letter {
        @include lumx-typography('subtitle1');
        color: lumx-color-variant('light', 'N');
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &--rounded {
        border-radius: 50%;
    }

    &-l {
        @include lumx-typography('title');
    }

    &-xs {
        @include lumx-typography('overline');
    }
}
