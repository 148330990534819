@import '@lumapps/lumx/scss/variables.scss';

$login-components-spacing: $lumx-spacing-unit-big * 2;
.login-page {
    @include lumx-elevation(4);
    width: 456px;
    text-align: center;
    border-radius: $lumx-spacing-unit;
    margin: calc(#{$lumx-spacing-unit-huge * 2}) auto;;
    position: absolute;
    bottom: 0;
    right: 0;
    top: 10%;
    height: fit-content;
    left:0;
    padding-bottom: $lumx-spacing-unit-big * 4;
    .wizard__back-button {
        position: absolute;
        top: $lumx-spacing-unit-big;
        left: $lumx-spacing-unit-big;
    }
    @include mq($until: tablet) {
        width: unset;
    }
    @include mq($until: mobile) {
        height: 100%;
        left: 0;
        margin: 0;
        top: 0;
    }

    &__logo {
        display: block;
        margin: $login-components-spacing auto 0 auto;
        height: $login-components-spacing;
        max-width: 100%;

        @include mq($until: mobile) {
            height: $lumx-spacing-unit-big + $lumx-spacing-unit-tiny;
        }
    }

    &__title {
        margin-top: $login-components-spacing;
    }

    &__subtitle {
        @include lumx-typography('body1');
        margin-top: $lumx-spacing-unit !important;
        color: lumx-color-variant('dark', 'L2');
    }

    &__content {
        margin-top: $login-components-spacing;
    }

    &__skeleton-button {
        margin: $lumx-spacing-unit-big auto;
    }

    &__skeleton-subtitle {
        margin: $lumx-spacing-unit-huge auto 0 auto;
    }
}
