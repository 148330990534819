@import '@lumx/core/scss/lumx.scss';
@import './design-tokens.scss';
@import './animations.scss';
@import './wrapper.scss';
@import './mixins.scss';
@import '@lumapps/classnames/index.scss';

/**
 * When using the new LumApps theme, text fields no longer have
 * paddings between them. In order to make development transparent
 * for both material and lumapps theme, we will add this padding to all text fields
 * so that developers do not need to code their own CSS and fix that issue each time
 * they use a Text Field.
 */
.theme-lumapps .lumx-text-field,
.theme-lumapps .lumx-select {
    padding-top: $lumx-spacing-unit-big;
    padding-bottom: $lumx-spacing-unit-regular;
}
