@import '@lumapps/lumx/scss/variables.scss';

.check-emails-page {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__icon-wrapper {
    margin-left:35%;
    margin-bottom:calc(#{$lumx-spacing-unit-huge} + 6px);
  }

  &__reset-password-link {
    margin-top: calc(#{$lumx-spacing-unit-huge} + 6px);
  }
    &__link-text {
    margin-left: $lumx-spacing-unit-tiny;
  }
}
