// Page widths
$page-width: 960px;
$page-width-wide: 1128px;
$page-width-compact-mobile: 552px;
$page-width-compact-desktop: 936px;
$page-width-narrow-mobile: 552px;
$page-width-narrow-desktop: 744px;

@mixin wrapper($width: 'wide') {
    @include mq($until: small) {
        margin-right: $lumx-spacing-unit-big;
        margin-left: $lumx-spacing-unit-big;
        max-width: calc(100vw - #{$lumx-spacing-unit-big * 2});
    }

    @include mq($until: desktop) {
        margin-right: $lumx-spacing-unit-huge;
        margin-left: $lumx-spacing-unit-huge;
        max-width: calc(100vw - #{$lumx-spacing-unit-huge * 2});
    }

    @include mq($from: desktop) {
        margin-right: auto;
        margin-left: auto;
    }

    @include mq($from: desktop, $until: wide) {
        width: $page-width;
    }

    @include mq($from: wide) {
        width: $page-width-wide;
    }

    @if ($width == 'compact') {
        @include mq($from: tablet, $until: desktop) {
            width: $page-width-compact-mobile;
            margin-left: auto;
            margin-right: auto;
        }
    
        @include mq($from: desktop) {
            width: $page-width-compact-desktop;
        }
    }

    @if ($width == 'narrow') {
        @include mq($from: tablet, $until: desktop) {
            width: $page-width-narrow-mobile;
            margin-left: auto;
            margin-right: auto;
        }
    
        @include mq($from: desktop) {
            width: $page-width-narrow-desktop;
        }
    }
}