.not-scrollable {
    overflow-y: scroll;
    position: fixed;
    width: 100%;
}

/**
 * class to visually hide an element but leave it in the dom and the accessibility tree.
 **/
.visually-hidden {
    @include visually-hidden();
}

// This adds a bottom separator
.lumx-separator {
    border-bottom: 1px solid var(--lumx-color-dark-L5);
}

/**
 * This add the required *.
 **/
.required {
    @include required();
}
