/**
 * This file regroups CSS variables used as design tokens to provide customizable styles.

Example:
:root {
    --lumapps-content-block-padding: 45px;
}
 */

:root {
    --lumx-app-background: var(--lumx-color-dark-L6);
    --lumx-app-background-alt: var(--lumx-color-light-N);
    --lumx-app-header-logo-height: 32px;
    --lumx-app-header-box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.12);
    --lumx-app-header-border-bottom-width: 0;
    --lumx-app-header-border-bottom-color: transparent;
    --lumx-typography-custom-metadata-font-family: var(--lumx-typography-font-family);
    --lumx-typography-custom-metadata-font-size: 14px;
    --lumx-typography-custom-metadata-font-weight: 400;
    --lumx-typography-custom-metadata-line-height: 20px;
    --lumx-typography-custom-metadata-font-style: normal;
    --lumx-typography-custom-tag-font-family: var(--lumx-typography-font-family);
    --lumx-typography-custom-tag-font-size: 14px;
    --lumx-typography-custom-tag-font-weight: 400;
    --lumx-typography-custom-tag-line-height: 20px;
    --lumx-widget-title-theme-light-color: var(--lumx-color-dark-N);
    --lumx-widget-title-theme-dark-color: var(--lumx-color-light-N);
    --lumx-widget-title-text-dark-color: var(--lumx-color-light-N);
    --lumx-widget-title-text-transform: none;
    --lumx-widget-title-text-align: start;
    --lumx-widget-title-alignment-h: flex-start;
    --lumx-widget-title-alignment-v: flex-start;
    --lumx-widget-intro-theme-light-color: var(--lumx-color-dark-N);
    --lumx-widget-intro-theme-dark-color: var(--lumx-color-light-N);
    --lumx-widget-intro-text-align: start;
    --lumx-widget-intro-alignment-h: flex-start;
    --lumx-metadata-list-separator: '-';
    --lumx-metadata-height: 20px;
    --lumx-metadata-border-radius: 0;
    --lumx-metadata-state-default-padding-horizontal: 0;
    --lumx-metadata-state-default-border-width: 0;
    --lumx-metadata-state-default-theme-light-background-color: transparent;
    --lumx-metadata-state-default-theme-light-color: var(--lumx-color-dark-L2);
    --lumx-metadata-state-default-theme-light-border-color: transparent;
    --lumx-metadata-state-default-theme-dark-background-color: transparent;
    --lumx-metadata-state-default-theme-dark-color: var(--lumx-color-light-L2);
    --lumx-metadata-state-default-theme-dark-border-color: transparent;
    --lumx-tag-list-separator: '-';
    --lumx-tag-height: 20px;
    --lumx-tag-border-radius: 0;
    --lumx-tag-state-default-padding-horizontal: 0;
    --lumx-tag-state-default-border-width: 0;
    --lumx-tag-state-default-theme-light-background-color: transparent;
    --lumx-tag-state-default-theme-light-color: var(--lumx-color-dark-L2);
    --lumx-tag-state-default-theme-light-border-color: transparent;
    --lumx-tag-state-default-theme-dark-background-color: transparent;
    --lumx-tag-state-default-theme-dark-color: var(--lumx-color-light-L2);
    --lumx-tag-state-default-theme-dark-border-color: transparent;
    --lumx-content-block-title-theme-light-color: var(--lumx-color-dark-N);
    --lumx-content-block-title-theme-dark-color: var(--lumx-color-light-N);
    --lumx-content-block-title-text-transform: none;
    --lumx-community-block-title-theme-light-color: var(--lumx-color-dark-N);
    --lumx-community-block-title-theme-dark-color: var(--lumx-color-light-N);
    --lumx-post-block-title-theme-light-color: var(--lumx-color-dark-N);
    --lumx-post-block-title-theme-dark-color: var(--lumx-color-light-N);
    --lumx-directory-entry-block-orientation-h-thumbnail-size: var(--lumx-size-m);
    --lumx-directory-entry-block-orientation-v-thumbnail-size: var(--lumx-size-m);
    --lumx-directory-entry-block-title-theme-light-color: var(--lumx-color-dark-N);
    --lumx-directory-entry-block-title-theme-dark-color: var(--lumx-color-light-N);
}
