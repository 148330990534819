@import '@lumapps/lumx/scss/variables.scss';
.sign-in-form {
  width: 280px;
  margin-top:calc(#{-$lumx-spacing-unit-huge} - 4px);
  display: inline-block;
  &__first-input {
    text-align: initial;
    margin-top: $lumx-spacing-unit-regular;
  }
  &__input {
    text-align: initial;
  }
  &__eye-password-icon {
      margin-top: 50%;
    }
  &__button-form {
    margin: $lumx-spacing-unit-big 0;
  }
  &__forgot-password-link {
    margin-top: $lumx-spacing-unit-big * 2;
    font-size: $lumx-size-xxs;
  }
}
