@import '@lumapps/lumx/scss/variables.scss';
@import '@apps/core/styles/base.scss';
@import '@apps/core/styles/html.scss';

html {
    /**
    * override the value 'scroll' set by @apps/core/styles/html.scss
    * it allows to hide the scroll bar on the login page
    */
    overflow-y: visible;
}

body.login {
    display: flex;
    min-height: 100%;
    flex-direction: column;
}

#login-app {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
}
