@import '@lumapps/lumx/scss/variables';

.front-office-error-page {
    padding-top: $lumx-spacing-unit-huge * 2;
    text-align: center;
}

/** On the legacy app, we make sure that the page is centered by adding a display flex */
[ui-view="content"] {
    .front-office-error-page {
        display: flex;
    }
}
