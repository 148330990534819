@import '@lumapps/lumx/scss/variables';

.lumx-search-field {
    &__text-field {
        padding: 0 !important;

        .lumx-text-field__wrapper {
            border-radius: $lumx-spacing-unit-tiny 0 0 $lumx-spacing-unit-tiny;
            min-width: 250px;

            &:before {
                border-radius: $lumx-spacing-unit-tiny 0 0 $lumx-spacing-unit-tiny;
            }
        }
    }

    // Remove the left border radius of the icon button to match correctly the input
    &__icon-button {
        border-radius: 0 var(--lumx-button-border-radius) var(--lumx-button-border-radius) 0 !important;
    }

    // Make the search field smaller when there is not enough room for displaying it.
    &--compact {
        .lumx-search-field__text-field {
            .lumx-text-field__wrapper {
                min-width: initial;
                width: 160px;
            }
        }
    }
}

.theme-lumapps {
    .lumx-search-field {
        &__icon-button {
            border-radius: 0 $lumx-spacing-unit-tiny $lumx-spacing-unit-tiny 0 !important;
        }
    }
}
