@import '@lumapps/lumx/scss/variables.scss';

/**
* some variables are using the !important CSS rule to ensure we're overriding
* potential custom CSS styles on the lumx buttons
*/

.login-provider-button {
    margin-bottom: $lumx-spacing-unit-big;
    padding: calc(#{$lumx-spacing-unit-tiny} - 3px) !important;
    border-radius: var(--lumx-button-border-radius) !important;
    width: 350px;

    &__logo-wrapper {
        background-color: lumx-color-variant('light', 'N');
        width: calc(#{$lumx-size-m} - 4px);
        height: calc(#{$lumx-size-m} - 4px);
        border-radius: var(--lumx-button-border-radius) !important;
        justify-content: center;
        margin: 2px;
        margin-left: 1px;
        display: flex;
        flex: none;
    }

    &__logo {
        margin: auto;
    }

    &__text {
        justify-content: center;
        display: grid;
        width: 100%;
        padding-left: $lumx-spacing-unit-big;
        padding-right: $lumx-spacing-unit-big;
        direction: rtl;
    }

    & > span {
        width: 100%;
    }

    /**
    * for each type of login provider button, we overidde the lumx button css class
    * to avoid messing with the client's CSS customization.
    *    - LoginProviderButton only uses `emphasis-high` and `theme-light`
    *    - 3 states to override: default, hover and active
    */
    &--microsoft {
        --lumx-button-emphasis-high-state-default-theme-light-background-color: #2F2F2F;
        --lumx-button-emphasis-high-state-hover-theme-light-background-color: #2A2A2A;
        --lumx-button-emphasis-high-state-active-theme-light-background-color: #262626;

        --lumx-button-emphasis-high-state-default-theme-light-color: #FFFFFF;
        --lumx-button-emphasis-high-state-hover-theme-light-color: #FFFFFF;
        --lumx-button-emphasis-high-state-active-theme-light-color: #FFFFFF;
    }
    &--google {
        --lumx-button-emphasis-high-state-default-theme-light-background-color: #3771D0;
        --lumx-button-emphasis-high-state-hover-theme-light-background-color: #2D65C0;
        --lumx-button-emphasis-high-state-active-theme-light-background-color: #2859AA;

        --lumx-button-emphasis-high-state-default-theme-light-color: #FFFFFF;
        --lumx-button-emphasis-high-state-hover-theme-light-color: #FFFFFF;
        --lumx-button-emphasis-high-state-active-theme-light-color: #FFFFFF;
    }
    &--okta {
        --lumx-button-emphasis-high-state-default-theme-light-background-color: #007ABD;
        --lumx-button-emphasis-high-state-hover-theme-light-background-color: #006EAA;
        --lumx-button-emphasis-high-state-active-theme-light-background-color: #006297;
    
        --lumx-button-emphasis-high-state-default-theme-light-color: #FFFFFF;
        --lumx-button-emphasis-high-state-hover-theme-light-color: #FFFFFF;
        --lumx-button-emphasis-high-state-active-theme-light-color: #FFFFFF;
    }
    &--email {
        border: 1px solid var(--lumx-color-dark-L4) !important;

        --lumx-button-emphasis-high-state-default-theme-light-background-color: #FFFFFF;
        --lumx-button-emphasis-high-state-hover-theme-light-background-color: #E6E6E6;
        --lumx-button-emphasis-high-state-active-theme-light-background-color: #CCCCCC;
    
        --lumx-button-emphasis-high-state-default-theme-light-color: #2F2F2F;
        --lumx-button-emphasis-high-state-hover-theme-light-color: #2F2F2F;
        --lumx-button-emphasis-high-state-active-theme-light-color: #2F2F2F;
    }
}
