@import '@lumapps/lumx/scss/variables.scss';

.renew-password-form {
  width: $lumx-size-xxl;
  margin-top: -$lumx-spacing-unit-big;
  display: inline-block;
  &__input {
    text-align: initial;
  }
  &__re-captcha {
    transform:scale(0.85);
    transform-origin:0 0;
  }
  &__button {
    margin-top: $lumx-spacing-unit-regular;
    width: $lumx-size-xxl !important;
  }
  &__loader {
    margin-top: 100px;
    margin: auto;
  }
  &__error-message {
    margin-top: $lumx-spacing-unit-big;
    width: $lumx-size-xxl;
    display: inline-flex;
  }
  &__visually-hidden {
    @include visually-hidden();
  }
}
