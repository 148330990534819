
/**
  * This prevents the app from jumping around when we navigate between pages and
  * one of those pages needs a scroll and the other one doesn't, all of this while
  * using a connected mouse.
  * https://stackoverflow.com/questions/1417934/how-to-prevent-scrollbar-from-repositioning-web-page
  */
html {
  height: 100%;
  background-color: var(--lumx-app-background);
  overflow-y: scroll;

  &.alternative-bkg {
    background-color: var(--lumx-app-background-alt);
  }
}
